import Vue from "vue";
import VueRouter from "vue-router";
import { getAllLinks } from "../utils/event-bus";

// import { component } from "vue/types/umd";
/**
 * 重写路由的push方法
 */
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error);
};

// 路由懒加载
const Login = () => import("../views/Login.vue");
const Index = () => import("../views/Index.vue");
const Welcome = () => import("../views/auth/index/Welcome.vue");
const RoleMenu = () => import("../views/auth/roleMenu/RoleMenu.vue");

const employeeManagement = () =>
  import("../views/auth/employee/EmployeeManagement.vue");
const employeeDetail = () =>
  import("../views/auth/employee/EmployeeDetail.vue");
const OrganizationManagement = () =>
  import("../views/auth/organization/OrganizationManagement.vue");
const Post = () => import("../views/auth/post/Post.vue");
const Menu = () => import("../views/auth/menu/Menu.vue");
const Authority = () => import("../views/auth/authority/Authority.vue");
const OrganizationTypeMaintenance = () =>
  import("../views/auth/organizationType/OrganizationType.vue");
const myAccountView = () => import("../views/auth/my/MyAccountView.vue");
const SystemConfig = () => import("../views/systemConfig/SystemConfig.vue");
const SystemConfigDetails = () =>
  import("../views/systemConfig/SystemConfigDetails.vue");
const TableDesign = () => import("../views/coding/tableDesign/TableDesign.vue");
const Table = () => import("../views/coding/table/Table.vue");
const TableDetails = () => import("../views/coding/table/TableDetails.vue");
const TableDdl = () => import("../views/coding/tableDdl/TableDdl.vue");
const TableDdlDetails = () =>
  import("../views/coding/tableDdl/TableDdlDetails.vue");
// const FromDesigner = () => import('../views/fromDesigner/FromDesigner.vue')

const LoginLog = () => import("../views/loginLog/LoginLog.vue");
const LoginLogDetails = () => import("../views/loginLog/LoginLogDetails.vue");
const EngineeringSystemUpdateRecord=()=>import('../views/engineeringSystemUpdateRecord/EngineeringSystemUpdateRecord.vue');

//design
const FromDesigner = () => import("../components/formDesigner.vue");
const DialogTest = () => import("../views/fromDesigner/dialogTest.vue");
const QueryDialogTest = () =>
  import("../views/fromDesigner/queryDialogTest.vue");
const View = () => import("../views/fromDesigner/view.vue");
const DesignTable = () => import("../views/fromDesigner/table.vue");
const CustomerList = () => import("../views/customerManage/CustomerList.vue");
const MembersManage = () =>
  import("../views/permissionManage/MembersManage.vue");
const OrderList = () => import("../views/orderList/index.vue");
const Bill = () => import("../views/bill/bill.vue");
const Bill1 = () => import("../views/bill/bill1.vue");
const Settlement = () => import("../views/settlement/settlement.vue");
const Settlement1 = () => import("../views/settlement/settlement1.vue");
const RoleMemberManagement = () =>
  import("../views/roleMemberManagement/index.vue");
const MaterialManage = () =>
  import("../views/materialManage/materialManage.vue");
const expoleManage = () => import("../views/exploeManage/expoleManage.vue");


// const expoleManageDetailShow = () => import("../views/exploeManage/expoleManageDetailShow.vue");
const exploeIncrease = () => import("../views/exploeManage/exploeIncrease.vue");
const orderManage = () => import("../views/orderManage/orderManage.vue");
const userManage = () => import("../views/userManage/userManage.vue");
const openSea = () => import("../views/userManage/openSea.vue");
const newCustomer = () => import("../views/userManage/newCustomer.vue");
const firstPage = () => import("../views/exploeManage/firstPage.vue")
const addUserManage = () => import('../views/userManage/addUserManage.vue')
const agentIndex = () => import('../views/agentManagement/agentIndex.vue')
const agentManagement = () => import('../views/agentManagement/agentManagement.vue')
const agentManagement1 = () => import('../views/agentManagement/agentManagement1.vue')
const agentAssessment = () => import('../views/agentManagement/agentAssessment.vue')
const assessmentIndex = () => import('../views/agentManagement/assessmentIndex.vue')
const agentAdd = () => import('../views/agentManagement/agentAdd.vue')
const agentScheme = () => import('../views/agentManagement/agentScheme.vue')
const constructionList = () => import('../views/agentManagement/constructionList.vue')
const dataCompletion = () => import('../views/userManage/dataCompletion.vue')
const dataCompletion1 = () => import('../views/userManage/dataCompletion1.vue')

const commerpro = () => import('../views/seh/commerpro.vue')
const prospecying = () => import('../views/seh/prospecting.vue')
const prospecying1 = () => import('../views/seh/prospecting1.vue')
const addpro = () => import('../views/seh/addpro.vue')
const accep = () => import('../views/seh/accep.vue')
const conso = () => import('../views/seh/conso.vue')
const inanceList = () => import('../views/inance/inanceList.vue')
const study = () => import('../views/study/study.vue')
const newsList = () => import('../views/news/newslist.vue')
const myList = () => import('../views/news/mynews.vue')
const addNews = () => import('../views/news/addnews.vue')
const memorandum = () => import('../views/memorandum/memorandum.vue')
const index2 = () => import('../views/index2.vue')

//财务
const costOverview = () => import('../views/financial/costOverview.vue')
const feeStandard = () => import('../views/financial/feeStandard.vue')
const installationFee = () => import('../views/financial/installationFee.vue')
const installationFeeDetail = () => import('../views/financial/installationFeeDetail.vue')
const salespersonCommission = () => import('../views/financial/salespersonCommission.vue')
const channelFeeDetail = () => import('../views/financial/channelFeeDetail.vue')
const balance = () => import('../views/financial/balance.vue')

Vue.use(VueRouter);

const routes = [
  { path: "/", redirect: "/login" },
  { path: "/login", component: Login },
  { path: "/test", component: () => import("../views/text.vue") },
  { path: "/404", component: () => import("../views/404.vue") },
  // { path: "*", redirect: "/404" },
  {
    path: "/index",
    component: Index,
    redirect: "/Welcome",
    children: [
      {
        path: "/Welcome", component: index2},
      // { path: "/index", component: Welcome },
      { path: "/employee", component: employeeManagement },
      { path: "/employee/details", component: employeeDetail },
      { path: "/organization", component: OrganizationManagement },
      { path: "/post", component: Post },
      { path: "/menu", component: Menu },
      { path: "/authority", component: Authority },
      { path: "/organizationType", component: OrganizationTypeMaintenance },
      { path: "/myAccountView", component: myAccountView },
      { path: "/roleMenu", component: RoleMenu },
      { path: "/systemConfig", component: SystemConfig },
      { path: "/systemConfig/details", component: SystemConfigDetails },
      { path: "/tableDesign", component: TableDesign },
      { path: "/coding", component: Table },
      { path: "/table/details", component: TableDetails },
      { path: "/tableDdl", component: TableDdl },
      { path: "/tableDdl/details", component: TableDdlDetails },
      { path: "/loginLog", component: LoginLog },
      { path: "/loginLog/details", component: LoginLogDetails },
      { path: "/expoleManage/exploeIncrease", component: exploeIncrease },
      { path: "/expoleManage/firstPage", component: firstPage },
      // { path: "/expoleManageDetail", component: expoleManageDetail },
      // { path: "/expoleManageDetailShow", component: expoleManageDetailShow },
      { path: "/userManage/openSea", component: openSea },
      { path: "/userManage/newCustomer", component: newCustomer },
      { path: "/addUserManage", component: addUserManage },
      { path: "/agentManagement/agentIndex", component: agentIndex },
      { path: "/agentManagement/agentManagement", component: agentManagement },
      { path: "/agentManagement/agentManagement1", component: agentManagement1 },
      { path: "/agentManagement/agentAssessment", component: agentAssessment },
      { path: "/agentManagement/assessmentIndex", component: assessmentIndex },
      { path: "/agentManagement/agentAdd", component: agentAdd },
      { path: "/agentManagement/agentScheme", component: agentScheme },
      { path: "/agentManagement/constructionList", component: constructionList },
      { path: "/userManage/dataCompletion", component: dataCompletion },
      { path: "/userManage/dataCompletion1", component: dataCompletion1 },
      { path: "/memorandum", component: memorandum },
      {path:'/systemUpdateRecord',component:EngineeringSystemUpdateRecord},


      { path: "/fromDesigner", name: "designer", component: FromDesigner },
      { path: "/dialog", name: "dialogTest", component: DialogTest },
      {
        path: "/queryDialog",
        name: "queryDialogTest",
        component: QueryDialogTest
      },
      { path: "/view", name: "view", component: View },
      { path: "/table", name: "table", component: DesignTable },
      { path: "/customerList", name: "customerList", component: CustomerList },
      {
        path: "/membersManage",
        name: "membersManage",
        component: MembersManage
      },
      { path: "/table", name: "table", component: DesignTable },
      { path: "/orderList", name: "orderList", component: OrderList },
      { path: "/bill", name: "bill", component: Bill },
      { path: "/bill1", name: "bill", component: Bill1 },
      { path: "/settlement", name: "settlement", component: Settlement },
      { path: "/settlement1", name: "settlement1", component: Settlement1 },
      { path: "/roleMemberManagement", component: RoleMemberManagement },
      { path: "/materialManage", component: MaterialManage },
      { path: "/orderManage", component: orderManage },
      { path: "/userManage", component: userManage },
      { path: "/expoleManage", component: expoleManage },
      { path: "/prospecying", component: prospecying },
      { path: "/prospecying1", component: prospecying1},

      { path: "/commerpro", component: commerpro },
      { path: "/addpro", component: addpro },
      { path: "/accep", component: accep },
      { path: "/conso", component: conso },
      { path: "/inanceList", component: inanceList },
      { path: "/study", component: study },
      { path: "/newsList", component: newsList },
      { path: "/addNews", component: addNews },
      { path: "/myList", component: myList },
      { path: "/feeStandard", component: feeStandard },
      { path: "/installationFee", component: installationFee },
      { path: "/installationFeeDetail", component: installationFeeDetail },
      { path: "/salespersonCommission", component: salespersonCommission },
      { path: "/channelFeeDetail", component: channelFeeDetail },
      { path: "/balance", component: balance, name: 'balance' },
      { path: "/costOverview", component: costOverview },


      {
        path: "/intoView",
        component: () => import("../views/warehouse/IntoView.vue")
      },
      {
        path: "/outView",
        component: () => import("../views/warehouse/outView.vue")
      },
      {
        path: "/currenInventory",
        component: () => import("../views/warehouse/currenInventory.vue")
      },
      {
        path: "/detailsInventory",
        component: () => import("../views/warehouse/detailsInventory.vue")
      },
      {
        path: "/returnsView",
        component: () => import("../views/warehouse/returnsView.vue")
      },
      {
        path: "/deliveryRequest",
        component: () => import("../views/warehouse/deliveryRequest.vue")
      },
      {
        path: "/pickupWaybill",
        component: () => import("../views/warehouse/pickupWaybill.vue")
      },
      { path: "/engineerDept", component: () => import("../views/organization/engineerDept/engineerDept.vue") },
      { path: "/employeeManagement", component: () => import("../views/organization/employee/EmployeeManagement.vue") },
      { path: "/employeeManagement/detail", component: () => import("../views/organization/employee/EmployeeDetail.vue") },
      { path: "/engineerRole", component: () => import("../views/organization/engineeringRole/EngineeringRole.vue") },
      { path: "/organization/post", component: () => import("../views/organization/post/post.vue") },
      { path: "/organization/menu", component: () => import("../views/organization/menu/menu.vue") },
      {path:"/agentManagement/agentEdit", component: () => import("../views/agentManagement/agentEdit.vue")},
      {path:"/storehouse", component: () => import("../views/warehouse/storehouse.vue")},
      {path:"/addAgent", component: () => import("../views/agentManagement/agentAdd.vue")},
      {path:"/expoleManageDetail", component: () => import("../views/exploeManage/expoleManageDetail/expoleManageDetail.vue")},
      {path:"/expoleManageDetailshow", component: () => import("../views/exploeManage/expoleManageDetailshow/expoleManageDetail.vue")},

    ]
  }
];

const router = new VueRouter({
  routes
});

// 挂载路由导航守卫,to表示将要访问的路径，from表示从哪里来，next是下一个要做的操作 next('/login')强制跳转login
router.beforeEach((to, from, next) => {
  if (to.path === "/login") {
    next();
  } else {
    let token = ""; //token保存在localstorage中

    let item = localStorage.getItem("token");
    // let menu = JSON.parse(localStorage.getItem("menu"));
    // const result = getAllLinks(menu);
    // result.push("/login")
    // result.push("/index")
    // result.push("/404")
    // result.push("/")

    //如果result里面有访问 否则 404页面

    try {
      item = JSON.parse(item);
    } catch (error) {
      // eslint-disable-next-line no-self-assign
      item = item;
    }
    if (item && item.startTime) {
      let date = new Date().getTime();
      // 如果大于就是过期了，如果小于或等于就还没过期
      let timel = date - item.startTime;
      console.log("timelength:" + timel + ",expires:" + item.expires);
      if (timel < item.expires) {
        token = item.value;
      }
    }
    if (token === null || token === "") {
      next("/login");
    } else {
      // console.log(result,200);
      // console.log(to.path,201);
      // console.log(result.includes(to.path));
      // if (result.includes(to.path)) {
      //   next();
      // } else {
      //   console.log(111);
      // }
      next();
    }
  }
});
export default router;
