export default {
  namespaced: true,
  state: {
    form: {}, // 列表当前行
    showDialog: false, // 订单详情
    addDialog: false, // 添加订单
    materialsDialog: false, // 签收信息
    orderDetailDialog: false, // 并网资料
    materialsData: null,
    orderDetailData: null,
    form1: {}, // 签收信息
    fileList_1: []
  },
  getters: {},
  mutations: {
    changeForm(state, payload) {
      state.form = payload;
    },
    changeShowDialog(state, payload) {
      state.showDialog = payload;
    },
    changeAddDialog(state, payload) {
      state.addDialog = payload;
    },
    changeMaterialsDialog(state, payload) {
      state.materialsDialog = payload;
    },
    changeOrderDetailDialog(state, payload) {
      state.orderDetailDialog = payload;
    },
    changeMaterialsData(state, payload) {
      state.materialsData = payload;
    },
    changeOrderDetailData(state, payload) {
      state.orderDetailData = payload;
    },
    changeForm1(state, payload) {
      state.form1 = payload;
    },
    changeFileList(state, payload) {
      state.fileList_1 = payload;
    }
  }
};
