import { add, remove } from "../../views/exploeManage/common";

export default {
  namespaced: true,
  state: {
    imgs: {}, // 身份证图片信息
    mainGate: [], // 正大门
    fullView: [], // 房屋全景照片
    mainRoof: [], // 主屋顶
    azimuth:[], //奥维定位
    surveyTable:[], //现刊表
  },
  getters: {},
  mutations: {
    // 身份证
    setImgs(state, data) {
      state.imgs = data;
    },
    removeImg(state, data) {
      for (let key in state.imgs) {
        if (state.imgs[key] == data) {
          state.imgs[key] = "";
        }
      }
    },
    update(state, data) {
      state.imgs[data.type] = data.url;
    },
    //正大门
    setList(state, data) {
      if (data.type == "main_gate") {
        return (state.mainGate = data.value);
      }
      if(data.type == "full_view"){
        return (state.fullView = data.value);
      }
      if(data.type == "main_roof"){
        return (state.mainRoof = data.value)
      }
      if(data.type == "azimuth"){
        return (state.azimuth = data.value)
      }
      if(data.type=="survey_table"){
        return (state.surveyTable = data.value)
      }
    },
    //

    updateList(state, data) {
      if (data.type == "main_gate") {
        return (state.mainGate = add(data.type, data.url, state.mainGate));
      }
      if (data.type == "full_view") {
        return (state.fullView = add(data.type, data.url, state.fullView));
      }
      if (data.type == "main_roof") {
        return (state.mainRoof = add(data.type, data.url, state.mainRoof));
      }
      if (data.type == "azimuth") {
        return (state.azimuth = add(data.type, data.url, state.azimuth));
      }
      if(data.type=="survey_table"){
        return (state.surveyTable = add(data.type, data.url, state.surveyTable));
      }
    },
    remove(state, data) {
      if (data.type == "main_gate") {
        return remove(data.url, state.mainGate);
      }
      if (data.type == "full_view") {
        return remove(data.url, state.fullView);
      }
      if (data.type == "main_roof") {
        return remove(data.url, state.mainRoof);
      }
      if (data.type == "azimuth") {
        return remove(data.url, state.azimuth);
      }
      if(data.type=="survey_table"){
        return remove(data.url, state.surveyTable);
      }
    },
  },
};
