export const add = (explorateImgType, explorateImgUrl, array) => {
  let obj = {
    explorateImgType,
    explorateImgUrl
  };
 return  array = [...array, obj];
};


export const remove = (item, array) => {
  console.log(item,array.length);
  return array.splice(array.indexOf(item), 1);
}

export const remove2 = (item, array) => {
  const index = array.indexOf(item);
  if (index !== -1) {
    array.splice(index, 1);
  }
  return array;
}

export const removePreviewImgList = ( item,previewImgList )=>{
  console.log(item,16);
  return previewImgList.splice(previewImgList.indexOf(item), 1)
}
