// 导出页面为PDF格式
import html2Canvas from "html2canvas";
import JsPDF from "jspdf";
export default {
  install(Vue, options) {
    /**
     * @function pdf格式导出，不带分页，长图导出
     * @params ele 要下载的dom
     * @params info 报告用户信息
     * ***/
    Vue.prototype.getPdf = function(ele, info) {
      setTimeout(() => {
        var loadingInstance = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
        var width = ele.offsetWidth; //获取dom 宽度
        var height = ele.offsetHeight; //获取dom 高度

        ele.style.height = height + 200 + "px";
        ele.style.paddingTop = 60 + "px";

        ele.style.backgroundColor = "#060400";
        ele.style.zIndex = "-1";
        // ele.style.position = 'absolute';
        // ele.style.top = '0';

        var canvas = document.createElement("canvas"); //创建一个canvas节点
        var scale = 4; //定义任意放大倍数 支持小数
        canvas.width = width * scale; //定义canvas 宽度 * 缩放，在此我是把canvas放大了2倍
        canvas.height = height * scale; //定义canvas高度 *缩放
        canvas.getContext("2d"); //获取context,设置scale

        document.body.scrollTop = 0; // IE的
        document.documentElement.scrollTop = 0; // 其他
        ele.scrollTop = 0;
        html2Canvas(ele, {
          useCORS: true,
          allowTaint: true
        }).then(function(canvas) {
          var context = canvas.getContext("2d");
          // 【重要】关闭抗锯齿
          context.mozImageSmoothingEnabled = false;
          context.webkitImageSmoothingEnabled = false;
          context.msImageSmoothingEnabled = false;
          context.imageSmoothingEnabled = false;

          var imgData = canvas.toDataURL("image/jpeg", 1.0); //转化成base64格式,可上网了解此格式
          var img = new Image();
          img.src = imgData;
          img.onload = function() {
            img.width = img.width / scale; //因为在上面放大了2倍，生成image之后要/2
            img.height = img.height / scale;
            img.style.transform = "scale(0.5)";
            if (this.width > this.height) {
              //此可以根据打印的大小进行自动调节
              var doc = new JsPDF("l", "mm", [
                this.width * 1,
                this.height * 1
              ]);
            } else {
              var doc = new JsPDF("p", "mm", [
                this.width * 1,
                this.height * 1
              ]);
            }

            doc.addImage(
              imgData,
              "jpeg",
              0,
              0,
              this.width * 1,
              this.height * 1
            );
            doc.save(`${info.name}-${info.code}-${info.title}.pdf`);
          };
        });
        ele.style.height = height + "px";
        ele.style.paddingTop = 0 + "px";
        loadingInstance.close();
      }, 500);
    };
    /**
     * @function pdf格式导出，带分页，a4纸标准
     * @params ele 要下载的dom
     * @params pdfName 报告用户信息
     * ***/
    Vue.prototype.getPdfPage = function(ele, info) {
      setTimeout(() => {
        let eleW = ele.offsetWidth; // 获得该容器的宽
        let eleH = ele.offsetHeight; // 获得该容器的高
        let eleOffsetTop = ele.offsetTop; // 获得该容器到文档顶部的距离
        let eleOffsetLeft = ele.offsetLeft; // 获得该容器到文档最左的距离

        var canvas = document.createElement("canvas");
        var abs = 0;

        let win_in =
          document.documentElement.clientWidth || document.body.clientWidth; // 获得当前可视窗口的宽度（不包含滚动条）
        let win_out = window.innerWidth; // 获得当前窗口的宽度（包含滚动条）

        if (win_out > win_in) {
          // abs = (win_o - win_i)/2;    // 获得滚动条长度的一半
          abs = (win_out - win_in) / 2; // 获得滚动条宽度的一半
          // console.log(a, '新abs');
        }
        canvas.width = eleW * 2; // 将画布宽&&高放大两倍
        canvas.height = eleH * 2;

        var context = canvas.getContext("2d");
        context.scale(2, 2);
        context.translate(-eleOffsetLeft - abs, -eleOffsetTop);
        // 这里默认横向没有滚动条的情况，因为offset.left(),有无滚动条的时候存在差值，因此
        // translate的时候，要把这个差值去掉
        html2Canvas(ele, {
          dpi: 300,
          // allowTaint: true,  //允许 canvas 污染， allowTaint参数要去掉，否则是无法通过toDataURL导出canvas数据的
          useCORS: true //允许canvas画布内 可以跨域请求外部链接图片, 允许跨域请求。
        }).then(canvas => {
          var contentWidth = canvas.width;
          var contentHeight = canvas.height;
          //一页pdf显示html页面生成的canvas高度;
          var pageHeight = (contentWidth / 592.28) * 841.89;
          //未生成pdf的html页面高度
          var leftHeight = contentHeight;
          //页面偏移
          var position = 0;
          //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
          var imgWidth = 595.28;
          var imgHeight = (592.28 / contentWidth) * contentHeight;
          var pageData = canvas.toDataURL("image/jpeg", 1.0);
          var pdf = new JsPDF("", "pt", "a4");
          //有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
          //当内容未超过pdf一页显示的范围，无需分页
          if (leftHeight < pageHeight) {
            //在pdf.addImage(pageData, 'JPEG', 左，上，宽度，高度)设置在pdf中显示；
            pdf.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
            // pdf.addImage(pageData, 'JPEG', 20, 40, imgWidth, imgHeight);
          } else {
            // 分页
            while (leftHeight > 0) {
              pdf.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
              leftHeight -= pageHeight;
              position -= 841.89;
              //避免添加空白页
              if (leftHeight > 0) {
                pdf.addPage();
              }
            }
          }
          //可动态生成
          pdf.save(`${info.name}-${info.code}-${info.title}.pdf`);
        });
      }, 50);
    };
  }
};
