import { HYEventBus } from "hy-event-store";

const eventBus = new HYEventBus();

export default eventBus;

export // 写一个递归方法
function getAllLinks(menuData) {
  const links = [];

  function traverse(menu) {
    if (menu.link) {
      links.push(menu.link);
    }
    if (menu.childrens && menu.childrens.length > 0) {
      for (const child of menu.childrens) {
        traverse(child);
      }
    }
  }

  for (const menu of menuData) {
    traverse(menu);
  }

  return links;
}
