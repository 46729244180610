import Vue from "vue";
import Vuex from "vuex";
import orderList from "./modules/orderList";
import roleMemberManagement from "./modules/roleMemberManagement";
import expoleManageDetail from "./modules/expoleManageDetail";

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    userName: "",
    loginName: "",
    portalHtml: "",
  },
  mutations: {
    createUserName(state, info) {
      state.userName = info;
    },
    createPortalHtml(state, info) {
      state.portalHtml = info;
    },
    setMenu(state,menu){
      state.meun=menu
    }
  },
  getters: {
    getUserName(state) {
      return state.userName;
    },
    getPortalHtml(state) {
      return state.portalHtml;
    },
  },
  actions: {},
  modules: {
    orderList,
    roleMemberManagement,
    expoleManageDetail
  }
});
