export default {
  namespaced: true,
  state: {
    form: {},
    showDialog1: false, // 添加角色
    showDialog2: false, // 新增
    currentItem: {}
  },
  getters: {},
  mutations: {
    changeForm(state, payload) {
      state.form = payload;
    },
    changeShowDialog1(state, payload) {
      state.showDialog1 = payload;
    },
    changeShowDialog2(state, payload) {
      state.showDialog2 = payload;
    },
    changeCurrentItem(state, payload) {
      state.currentItem = payload;
    }
  }
};
